import React from "react";
import styled from "styled-components";
import { JobOfferInterface } from "../../../graphql/jobs";
import OfferEndDate from "./offer-end-date";

interface OfferSummaryProps {
  jobOffer: JobOfferInterface;
}

const OfferSummary: React.FC<OfferSummaryProps> = ({ jobOffer }) => (
  <Wrapper>
    <Title>We are looking for</Title>
    <OfferTitle>{jobOffer.title}</OfferTitle>
    <Title>{jobOffer.location}</Title>
    {jobOffer.endDate && (
      <DateWrapper>
        <OfferEndDate date={jobOffer.endDate} />
      </DateWrapper>
    )}
  </Wrapper>
);

const Wrapper = styled.div.attrs({ className: "pl-5 pr-5 tablet:pr-0" })``;

const Title = styled.p.attrs({
  className: `font-light text-white text-[1.375rem] mb-5 tablet:text-paragraphTablet last:mb-16`,
})``;

const OfferTitle = styled.h3.attrs({
  className: `font-bold text-subHeadlineMobile text-white tablet:text-modalHeadline`,
})``;

const DateWrapper = styled.div.attrs({ className: "hidden tablet:block" })``;

export default OfferSummary;
