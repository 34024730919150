import React from "react";

export function useScrollEvent(effect: () => void, element: any, isMobile: boolean) {
  if (!(typeof window !== "undefined")) return;

  let ticking = false;

  React.useEffect(() => {
    if (!element) return;

    const callback = () => {
      ticking = false;
      effect();
    };

    const handleScroll = () => {
      requestTick();
    };

    const requestTick = () => {
      if (!ticking) {
        requestAnimationFrame(callback);
      }
      ticking = true;
    };

    element.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      element.removeEventListener("scroll", handleScroll, { passive: true });
    };
  }, [element, isMobile]);
}
