import React from "react";

export function useResize() {
  const [windowHeight, setWindowHeight] = React.useState<number | null>(null);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [rootFontSize, setRootFontSize] = React.useState<number>(0);

  const getRootFontSize: () => number = () => rootFontSize;

  const handleResize = () => {
    setWindowHeight(document.documentElement.clientHeight);
    setIsMobile(document.documentElement.clientWidth < 768);
    setRootFontSize(parseFloat(getComputedStyle(document.documentElement).fontSize));
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    handleResize();
  }, []);

  return { windowHeight, isMobile, getRootFontSize };
}
