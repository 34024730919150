import React from "react";
import styled from "styled-components";
import HorizontalSlider from "../../../components/horizontal-slider/horizontal-slider";
import { useMatchMedia } from "../../../hooks/use-match-media";
import {
  ActivitiesIcon,
  BudgetIcon,
  CalendarIcon,
  CashIcon,
  DiscountIcon,
  HomeIcon,
  IconInterface,
  MetroTrainIcon,
  OnboardingIcon,
  UmbrellaIcon,
} from "../../../images/icons/Icons";

interface BenefitsListProps {
  type: keyof BenefitTypesInterface;
}

export interface BenefitTypesInterface {
  none: Array<BenefitDataInterface>;
  all: Array<BenefitDataInterface>;
}

interface BenefitDataInterface {
  title: string;
  icon: IconInterface;
}

const benefits: { [key: string]: BenefitDataInterface } = {
  onboarding: {
    title: "warm welcome and structured on-boarding",
    icon: OnboardingIcon,
  },
  salary: {
    title: "competitive salary and bonus scheme",
    icon: CashIcon,
  },
  working: {
    title: "working in a hybrid model",
    icon: HomeIcon,
  },
  leave: {
    title: "25 days paid annual leave",
    icon: CalendarIcon,
  },
  devolpment: {
    title: "personal development budget",
    icon: BudgetIcon,
  },
  discount: {
    title: "up to 45% discount on  Miele products",
    icon: DiscountIcon,
  },
  activities: {
    title: "regular work-social activities",
    icon: ActivitiesIcon,
  },
  transport: {
    title: "NS Business Card and Swap Fiets",
    icon: MetroTrainIcon,
  },
  retirement: {
    title: "retirement plan fully covered by Miele X ",
    icon: UmbrellaIcon,
  },
};

const benefitsTypes: BenefitTypesInterface = {
  none: [],
  all: Object.values(benefits),
};

const BenefitsList: React.FC<BenefitsListProps> = ({ type }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const match = useMatchMedia("(max-width: 767.8px)");

  React.useEffect(() => {
    setIsMobile(match);
  }, [match]);

  return (
    <>
      {isMobile && (
        <HorizontalSlider padding="px-8" progressMargin="mt-6 mb-0">
          {benefitsTypes[type].map((benefit, index) => (
            <Benefit key={`benefit_${index}`}>
              <BenefitIconWrapper>
                <BenefitIcon src={benefit.icon.src} alt={benefit.icon.alt} />
              </BenefitIconWrapper>
              <BenefitTitle>{benefit.title}</BenefitTitle>
            </Benefit>
          ))}
        </HorizontalSlider>
      )}
      {!isMobile && (
        <Wrapper>
          {benefitsTypes[type].map((benefit, index) => (
            <Benefit key={`benefit_${index}`}>
              <BenefitIconWrapper>
                <BenefitIcon src={benefit.icon.src} alt={benefit.icon.alt} />
              </BenefitIconWrapper>
              <BenefitTitle>{benefit.title}</BenefitTitle>
            </Benefit>
          ))}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div.attrs({
  className:
    "grid grid-flow-col overflow-x-auto discrete-scrollbar pb-8 tablet:pb-0 tablet:grid-cols-3 gap-4 tablet:grid-flow-row tablet:pr-10",
})``;
const Benefit = styled.div.attrs({
  className: "bg-gray15 p-4 rounded-lg w-44 tablet:w-full",
})``;

const BenefitIconWrapper = styled.div.attrs({ className: "pb-2.5 h-12" })`
  min-height: 3rem;
`;

const BenefitIcon = styled.img.attrs({ className: "max-h-full w-auto" })``;

const BenefitTitle = styled.p.attrs({
  className: "text-paragraph font-bold text-white",
})``;

export default BenefitsList;
