import React from "react";
import styled from "styled-components";
import { useScrollEvent } from "../../hooks/use-scroll-event";
import { useResize } from "../../hooks/use-resize";
import ArrowComponent from "./arrow-component";

interface ArrowProps {
  left?: boolean;
}

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

interface HiddeButton extends ButtonProps {
  hiddeButton: true | false;
}

interface ComponentProps {
  padding?: string;
  progressMargin?: string;
  recruitmentProcess?: boolean;
}

interface SpacingProps {
  padding?: string;
  margin?: string;
  recruitmentProcess?: boolean;
}

const HorizontalSlider: React.FC<ComponentProps> = ({ children, padding, progressMargin, recruitmentProcess }) => {
  const scrollableWrapperRef = React.useRef<HTMLDivElement | any>(null);
  const progressBarRef = React.useRef<HTMLProgressElement | null | any>(null);
  const ButtonLeftRef = React.useRef<HTMLButtonElement | null>(null);
  const ButtonRightRef = React.useRef<HTMLButtonElement | null>(null);

  const mouseDown = React.useRef<boolean>(false);

  const prevTimestamp = React.useRef<number | null>(null);

  const { isMobile, getRootFontSize } = useResize();

  const { current: scrollableRef } = scrollableWrapperRef;

  const effect = () => {
    const max: any = scrollableRef && scrollableRef.scrollWidth;
    const scrolled = Math.ceil(scrollableRef.scrollLeft);
    const progress = scrolled + scrollableRef.clientWidth;

    progressBarRef.current.max = max;
    progressBarRef.current.value = progress;

    const hiddenClassName = "hidden";

    if (ButtonLeftRef.current) {
      if (scrolled === 0) {
        ButtonLeftRef.current.classList.add(hiddenClassName);
        handleMouseUp();
      } else {
        ButtonLeftRef.current.classList.remove(hiddenClassName);
      }
    }

    if (ButtonRightRef.current) {
      if (progress >= max) {
        ButtonRightRef.current.classList.add(hiddenClassName);
        handleMouseUp();
      } else {
        ButtonRightRef.current.classList.remove(hiddenClassName);
      }
    }
  };

  useScrollEvent(effect, scrollableRef, isMobile);

  const handleMouseDown = (direction: number) => () => {
    if (!direction) return;

    const scroll = (timestamp: number) => {
      if (!mouseDown.current) return;

      if (prevTimestamp.current === null) {
        prevTimestamp.current = timestamp;
      }

      const timeDiff = timestamp - prevTimestamp.current;

      scrollableRef?.scrollBy(direction * timeDiff * getRootFontSize() * 0.1, 0);

      prevTimestamp.current = timestamp;

      requestAnimationFrame(scroll);
    };

    mouseDown.current = true;
    requestAnimationFrame(scroll);
  };

  const handleMouseUp = () => {
    mouseDown.current = false;
    prevTimestamp.current = null;
  };

  return (
    <Section>
      <ScrollableWrapper ref={scrollableWrapperRef}>
        <Wrapper padding={padding} recruitmentProcess={recruitmentProcess}>
          {children}
        </Wrapper>
      </ScrollableWrapper>
      {scrollableRef && (
        <>
          <ButtonLeft
            ref={ButtonLeftRef}
            hiddeButton={scrollableRef.scrollLeft === 0}
            onMouseDown={handleMouseDown(-1)}
            onTouchStart={handleMouseDown(-1)}
            onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
            aria-label="previous arrow"
          >
            <ArrowWrapper left>
              <IcoWrapper>
                <ArrowComponent />
              </IcoWrapper>
            </ArrowWrapper>
          </ButtonLeft>
          <ButtonRight
            ref={ButtonRightRef}
            hiddeButton={scrollableRef.scrollLeft + scrollableRef.clientWidth === scrollableRef.scrollWidth}
            onMouseDown={handleMouseDown(1)}
            onTouchStart={handleMouseDown(1)}
            onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
            aria-label="next arrow"
          >
            <ArrowWrapper>
              <IcoWrapper>
                <ArrowComponent />
              </IcoWrapper>
            </ArrowWrapper>
          </ButtonRight>
        </>
      )}

      <ProgressBar
        padding={padding}
        margin={progressMargin}
        ref={progressBarRef}
        max={scrollableRef ? scrollableRef.scrollWidth : "1"}
        value={scrollableRef ? scrollableRef.scrollLeft + scrollableRef.clientWidth : "0"}
      />
    </Section>
  );
};

export default HorizontalSlider;

const ArrowWrapper = styled.div.attrs<ArrowProps>(({ left }) => ({
  className: `${
    left ? "rotate-180" : ""
  } hidden tablet:flex  w-10 h-10 rounded-full  border border-white flex items-center justify-center`,
}))<ArrowProps>``;

const IcoWrapper = styled.div.attrs({
  className: "w-3 ml-1",
})``;

const Wrapper = styled.div.attrs<SpacingProps>(({ padding, recruitmentProcess }) => ({
  className: `grid grid-flow-col w-fit ${recruitmentProcess ? "gap-x-2" : "gap-x-4"} ${
    padding ? padding : "px-5 tablet:px-20"
  }`,
}))<SpacingProps>`
  ${({ recruitmentProcess }) => recruitmentProcess && "grid-template-rows: auto 5rem auto"}
`;

const ScrollableWrapper = styled.div.attrs({
  className: "mb-8 overflow-x-scroll	",
})`
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Button = styled.button.attrs<ButtonProps>({
  className:
    "absolute -translate-y-1/2 bottom-0 p-0 border-none bg-none outline-none	hover:cursor-pointer transition-all	duration-200 ease-linear",
})<ButtonProps>`
  top: calc(50% - 3rem);
`;

const ButtonLeft = styled(Button).attrs<HiddeButton>(({ hiddeButton }) => ({
  className: `${hiddeButton ? "hidden" : ""} tablet:left-32 tablet:px-4`,
}))<HiddeButton>``;

const ButtonRight = styled(Button).attrs<HiddeButton>(({ hiddeButton }) => ({
  className: `${hiddeButton ? "hidden" : ""} tablet:right-32 tablet:px-4`,
}))<HiddeButton>``;

const ProgressBar = styled.progress.attrs<SpacingProps>(({ padding, margin }) => ({
  className: `block w-full min-h-px h-1.5 appearance-none	border-none  ${margin ? margin : "mt-6 mb-12"}  ${
    padding ? padding : "px-5 tablet:px-20"
  }`,
}))<SpacingProps>`
  &,
  &::-webkit-progress-bar {
    background: #313131;
    background-clip: content-box;
  }

  &::-moz-progress-bar {
    background: #4b4b4b;
  }
  &::-webkit-progress-value {
    background: #4b4b4b;
  }
`;

const Section = styled.section.attrs({ className: "relative pb-px" })``;
