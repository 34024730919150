import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Routes } from "../../data/routing";
import { ButtonLinkSolid } from "../button";
import { PimcoreImage } from "../pimcore-image";
import { Headline3, Paragraph } from "../typography";
import { Background, Content, ContentLimiter, ContentWrapper, Wrapper } from "./main-tile";

const BenefitsTile: React.FC = () => {
  const images = useStaticQuery(graphql`
    query {
      pimcore {
        desktop: getAsset(fullpath: "/pages/static/tiles-navigation/benefits.jpg") {
          ... thumbnail
        }
        mobile: getAsset(fullpath: "/pages/static/tiles-navigation/benefits-mobile.jpg") {
          ... thumbnail
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Background>
        <PimcoreImage image={images.pimcore.desktop} mobileImage={images.pimcore.mobile} modifier="h-full w-full object-cover" withAspectRatio />
      </Background>
      <Content>
        <ContentLimiter>
          <Headline3>Bringing the best out in <strong>you</strong></Headline3>
          <Paragraph>At Miele X, we aim to give you all you need to thrive with us. From a warm welcome to a fantastic Community Space.</Paragraph>
        </ContentLimiter>
        <ContentWrapper>
          <ButtonLinkSolid to={Routes.benefits}>Discover your benefits</ButtonLinkSolid>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
};

export default BenefitsTile;