import React from "react";
import styled from "styled-components";
import CountrySuccessBackground from "./country-success-and-knowledge-management-background";
import CustomerEngagementBackground from "./customer-engagement-background";
import DataAndAnalyticsBackground from "./data-and-analytics-background";
import DigitalMarketingBackground from "./digital-marketing-background";
import EcommerceBackground from "./ecommerce-background";
import HrBackground from "./hr-background";
import ItBackground from "./it-background";
import OperationalExcellenceBackground from "./operational-excellence-and-information-management-background";
import OtherBackground from "./other-background";
import StrategyResearchBackground from "./strategy-research-and-development-background";

interface OfferBackgroundProps {
  category: string;
}

const OfferBackground: React.FC<OfferBackgroundProps> = ({ category }) => {
  const displayBackground = React.useCallback(() => {
    switch (category) {
      case 'ecommerce':
        return <EcommerceBackground />;
      case 'data-and-analytics':
        return <DataAndAnalyticsBackground />;
      case 'it':
        return <ItBackground />;
      case 'customer-engagement-and-performance-marketing':
        return <CustomerEngagementBackground />;
      case 'country-success-and-knowledge-management':
        return <CountrySuccessBackground />;
      case 'strategy-research-and-development':
        return <StrategyResearchBackground />;
      case 'hr':
        return <HrBackground />;
      case 'operational-excellence-and-information-management':
        return <OperationalExcellenceBackground />;
      case 'digital-marketing':
        return <DigitalMarketingBackground />;
      default:
        return <OtherBackground />;
    }
  }, [category]);

  return (
    <Wrapper>
      <PictureWrapper>
        {displayBackground()}
      </PictureWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.header.attrs({ className: "relative" })``;

const PictureWrapper = styled.div.attrs({ className: "absolute left-0 top-0" })``;

export default OfferBackground;