import React from "react";
import styled from "styled-components";
import HorizontalSlider from "../../../components/horizontal-slider/horizontal-slider";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { theme } from "../../../styles/styles";

interface RecruitmentProcessProps {
  type: keyof RecruitmentProcessTypesInterface;
}

export interface RecruitmentProcessTypesInterface {
  standard: Array<RecruitmentProcessStepInterface>;
  technical: Array<RecruitmentProcessStepInterface>;
  business: Array<RecruitmentProcessStepInterface>;
  internship: Array<RecruitmentProcessStepInterface>;
  technicalWithTest: Array<RecruitmentProcessStepInterface>;
  businessWithTest: Array<RecruitmentProcessStepInterface>;
  internshipWithTest: Array<RecruitmentProcessStepInterface>;
}

interface RecruitmentProcessStepInterface {
  top: string | React.ReactNode;
  bottom?: string | React.ReactNode;
}

interface StepElementProps {
  last: boolean;
  index: number;
  steps: number;
  position?: "top" | "bottom";
}

const recruitmentTypes: RecruitmentProcessTypesInterface = {
  standard: [
    {
      top: "Intro with a recruiter",
      bottom: (
        <>
          Within <strong>5</strong> working days
        </>
      ),
    },
    {
      top: "First interview",
    },
    {
      top: "Second interview",
    },
    {
      top: "Final interview",
    },
    {
      top: "Offer",
    },
    {
      top: "Contract",
      bottom: (
        <>
          Maximum <strong>5</strong> weeks
        </>
      ),
    },
  ],
  technical: [
    {
      top: "Intro with a recruiter",
      bottom: (
        <>
          Within <strong>5</strong> working days
        </>
      ),
    },
    {
      top: "First interview",
    },
    {
      top: "Technical assigment",
    },
    {
      top: "Second interview",
    },
    {
      top: "Final interview",
    },
    {
      top: "Offer",
    },
    {
      top: "Contract",
      bottom: (
        <>
          Maximum <strong>5</strong> weeks
        </>
      ),
    },
  ],
  business: [
    {
      top: "Intro with a recruiter",
      bottom: (
        <>
          Within <strong>5</strong> working days
        </>
      ),
    },
    {
      top: "First interview",
    },
    {
      top: "Business case",
    },
    {
      top: "Second interview",
    },
    {
      top: "Final interview",
    },
    {
      top: "Offer",
    },
    {
      top: "Contract",
      bottom: (
        <>
          Maximum <strong>5</strong> weeks
        </>
      ),
    },
  ],
  internship: [
    {
      top: "Intro with a recruiter",
      bottom: (
        <>
          Within <strong>5</strong> working days
        </>
      ),
    },
    {
      top: "First interview",
    },
    {
      top: "Final interview",
    },
    {
      top: "Offer",
    },
    {
      top: "Contract",
      bottom: (
        <>
          Maximum <strong>5</strong> weeks
        </>
      ),
    },
  ],
  technicalWithTest: [
    {
      top: "Intro with a recruiter",
      bottom: (
        <>
          Within <strong>5</strong> working days
        </>
      ),
    },
    {
      top: "Test",
    },
    {
      top: "First interview",
    },
    {
      top: "Technical assigment",
    },
    {
      top: "Second interview",
    },
    {
      top: "Final interview",
    },
    {
      top: "Offer",
    },
    {
      top: "Contract",
      bottom: (
        <>
          Maximum <strong>5</strong> weeks
        </>
      ),
    },
  ],
  businessWithTest: [
    {
      top: "Intro with a recruiter",
      bottom: (
        <>
          Within <strong>5</strong> working days
        </>
      ),
    },
    {
      top: "Test",
    },
    {
      top: "First interview",
    },
    {
      top: "Business case",
    },
    {
      top: "Second interview",
    },
    {
      top: "Final interview",
    },
    {
      top: "Offer",
    },
    {
      top: "Contract",
      bottom: (
        <>
          Maximum <strong>5</strong> weeks
        </>
      ),
    },
  ],
  internshipWithTest: [
    {
      top: "Intro with a recruiter",
      bottom: (
        <>
          Within <strong>5</strong> working days
        </>
      ),
    },
    {
      top: "Test",
    },
    {
      top: "First interview",
    },
    {
      top: "Final interview",
    },
    {
      top: "Offer",
    },
    {
      top: "Contract",
      bottom: (
        <>
          Maximum <strong>5</strong> weeks
        </>
      ),
    },
  ],
};

export const RecruitmentProcess: React.FC<RecruitmentProcessProps> = ({ type }) => {
  const stepsCount = recruitmentTypes[type].length;
  const [isMobile, setIsMobile] = React.useState(false);
  const match = useMatchMedia("(max-width: 767.8px)");

  React.useEffect(() => {
    setIsMobile(match);
  }, [match]);

  return (
    <>
      {isMobile && (
        <HorizontalSlider padding="px-8" progressMargin="mt-6 mb-0" recruitmentProcess>
          {recruitmentTypes[type].map((step, index) => {
            const stepProps: StepElementProps = {
              index,
              last: index === stepsCount - 1,
              steps: stepsCount,
            };
            return (
              <React.Fragment key={`recruitment_process_step_${index}`}>
                <Cell {...stepProps} position="top">
                  <StepTitle {...stepProps}>{step.top}</StepTitle>
                </Cell>
                <Cell {...stepProps}>
                  <StepPoint>
                    <StepMarker />
                    <StepDotWrapper {...stepProps} />
                    <StepDot />
                    {!stepProps.last && (
                      <ArrowWrapper>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.565 14.314">
                          <g id="Group_2528" data-name="Group 2528" transform="translate(1.5 2.12)">
                            <line
                              id="Line_124"
                              data-name="Line 124"
                              x2="44.005"
                              transform="translate(0 5.037)"
                              fill="none"
                              stroke="#1f1f1f"
                              strokeLinecap="round"
                              strokeWidth="3"
                            />
                            <path
                              id="Path_1769"
                              data-name="Path 1769"
                              d="M0,0,5.415,5.037,0,10.074"
                              transform="translate(40.15 0)"
                              fill="none"
                              stroke="#1f1f1f"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="3"
                            />
                          </g>
                        </svg>
                      </ArrowWrapper>
                    )}
                  </StepPoint>
                </Cell>
                <Cell {...stepProps} position="bottom">
                  <StepInfo {...stepProps}>{step?.bottom}</StepInfo>
                </Cell>
              </React.Fragment>
            );
          })}
        </HorizontalSlider>
      )}
      {!isMobile && (
        <Grid>
          {recruitmentTypes[type].map((step, index) => {
            const stepProps: StepElementProps = {
              index,
              last: index === stepsCount - 1,
              steps: stepsCount,
            };
            return (
              <React.Fragment key={`recruitment_process_step_${index}`}>
                <Cell {...stepProps} position="top">
                  <StepTitle {...stepProps}>{step.top}</StepTitle>
                </Cell>
                <Cell {...stepProps}>
                  <StepPoint>
                    <StepMarker />
                    <StepDotWrapper {...stepProps} />
                    <StepDot />
                    {!stepProps.last && (
                      <ArrowWrapper>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.565 14.314">
                          <g id="Group_2528" data-name="Group 2528" transform="translate(1.5 2.12)">
                            <line
                              id="Line_124"
                              data-name="Line 124"
                              x2="44.005"
                              transform="translate(0 5.037)"
                              fill="none"
                              stroke="#1f1f1f"
                              strokeLinecap="round"
                              strokeWidth="3"
                            />
                            <path
                              id="Path_1769"
                              data-name="Path 1769"
                              d="M0,0,5.415,5.037,0,10.074"
                              transform="translate(40.15 0)"
                              fill="none"
                              stroke="#1f1f1f"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="3"
                            />
                          </g>
                        </svg>
                      </ArrowWrapper>
                    )}
                  </StepPoint>
                </Cell>
                <Cell {...stepProps} position="bottom">
                  <StepInfo {...stepProps}>{step?.bottom}</StepInfo>
                </Cell>
              </React.Fragment>
            );
          })}
        </Grid>
      )}
    </>
  );
};

const Grid = styled.div.attrs({
  className: "grid grid-flow-col overflow-x-auto discrete-scrollbar gap-x-2 pb-4 tablet:py-0 tablet:auto-cols-fr",
})`
  grid-template-rows: auto 5rem auto;
  min-height: 12rem;
`;

const Cell = styled.div.attrs<StepElementProps>(({ index, last, position }) => ({
  className: `w-full ${last ? "bg-yellow/50" : index % 2 ? "bg-white/10" : "bg-white/5"}${
    position === "top" ? " rounded-t-lg" : ""
  }${position === "bottom" ? " rounded-b-lg" : ""}`,
}))<StepElementProps>`
  min-width: 6rem;

  @media ${theme.mediaQueries.tablet} {
    min-width: 4.5rem;
  }
`;

const StepTitle = styled.div.attrs<StepElementProps>(({ steps }) => ({
  className: `leading-tight text-center text-white font-bold pt-2 px-2.5 ${steps > 7 ? "text-xs" : "text-sm"}`,
}))<StepElementProps>``;

const StepInfo = styled.div.attrs<StepElementProps>(({ steps }) => ({
  className: `leading-tight text-center text-white font-light pb-2 px-2.5 ${steps > 7 ? "text-xs" : "text-sm"}`,
}))<StepElementProps>``;

const StepPoint = styled.div.attrs({ className: "relative h-full" })``;

const StepMarker = styled.div.attrs({
  className: "border-l border-1 border-white/20 absolute top-1 left-1/2 -translate-x-1/2",
})`
  height: 1.375rem;
`;

const StepDotWrapper = styled.div.attrs<StepElementProps>(({ index, last }) => ({
  className: `absolute top-1/2 -inset-x-1 -translate-y-1/2 backdrop-blur-sm bg-white/10 flex items-center justify-center py-4${
    index === 0 ? " ml-4 rounded-l-full" : ""
  }${last ? " mr-4 rounded-r-full" : ""}`,
}))<StepElementProps>``;

const StepDot = styled.div.attrs({
  className: "bg-yellow h-3 w-3 rounded-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2",
})``;

const ArrowWrapper = styled.div.attrs({
  className: "w-11 h-3 absolute top-1/2 -translate-y-1/2 z-10",
})`
  left: 75%;
`;
