import React from "react";
import styled from "styled-components";

interface OfferEndDateProps {
    date: string;
}

const OfferEndDate: React.FC<OfferEndDateProps> = ({ date }) => (
    <Wrapper>
        <Title>Application deadline</Title>
        <Date>{date}</Date>
    </Wrapper>
);

const Wrapper = styled.div.attrs({ className: "" })``;

const Title = styled.p.attrs({ className: "font-normal text-gray5 tablet:text-sm" })``;

const Date = styled.p.attrs({ className: `font-light text-white text-[1.375rem] tablet:text-sm` })``;

export default OfferEndDate;