import React from "react";
import styled from "styled-components";
import { PimcoreImage } from "../../../components/pimcore-image";
import { RecruiterInterface } from "../../../graphql/jobs";

interface OfferRecruiterProps {
  recruiter: RecruiterInterface;
}

const OfferRecruiter: React.FC<OfferRecruiterProps> = ({ recruiter }) => (
  <>
    <PhotoWrapper>
      <PimcoreImage image={recruiter.photo} modifier="w-full rounded-full" loading="eager" width={120} height={120} withAspectRatio />
    </PhotoWrapper>
    <Details>
      <RecruiterInfoHeader>
        <Title>
          <strong>{recruiter.name}</strong>
        </Title>
        <Title>{recruiter.title}</Title>
      </RecruiterInfoHeader>
      <Mail>{recruiter.email}</Mail>
    </Details>
  </>
);


const Details = styled.div.attrs({ className: "text-sm flex flex-col justify-between font-normal" })``;

const RecruiterInfoHeader = styled.div.attrs({ className: "" })``;

const Title = styled.p.attrs({ className: "text-white" })``;

const Mail = styled.p.attrs({ className: "text-yellow break-all" })``;

const PhotoWrapper = styled.div.attrs({ className: "rounded-full w-16 h-16 ml-3", })``;

export default OfferRecruiter;