import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/styles";
import { Headline3 } from "../typography";
import JobsSearchTile from "./main-tile";
import SmallTile, { SmallTileTypes } from "./small-tile";

interface TileNavigationProps {
  top: keyof SmallTileTypes;
  bottom: keyof SmallTileTypes;
}

const TileNavigation: React.FC<TileNavigationProps> = ({ children, top, bottom }) => {
  return (
    <Wrapper>
      <Headline3 as="h2" color={"text-gray"} align={"text-center"}>
        Moving digital at&nbsp;Miele forward. <strong>Together.</strong>
      </Headline3>
      <Grid>
        <MainCol>
          {children}
          {!children && <JobsSearchTile />}
        </MainCol>
        <SmallColTop>
          <SmallTile to={top} />
        </SmallColTop>
        <SmallColBottom>
          <SmallTile to={bottom} />
        </SmallColBottom>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.section.attrs({ className: "bg-white px-5 tablet:px-10 py-8 tablet:py-14 relative z-10" })``;

const Grid = styled.div.attrs({
  className: "grid grid-rows-4 tablet:grid-rows-2 tablet:grid-cols-3 gap-4 mt-8 tablet:mt-14",
})``;

const MainCol = styled.div.attrs({ className: "tablet:col-start-1 tablet:col-span-2 tablet:row-start-1 row-span-2" })`
  min-height: 24rem;

  @media ${theme.mediaQueries.tablet} {
    min-height: 32rem;
  }
`;

const SmallColTop = styled.div.attrs({ className: "tablet:col-start-3 tablet:row-start-1" })``;

const SmallColBottom = styled.div.attrs({ className: "tablet:col-start-3 tablet:rows-start-2" })``;

export default TileNavigation;
