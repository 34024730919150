import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/styles";
import { IconX } from "../icons";

interface ContentWrapperProps {
  withXBackground?: boolean;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({children, withXBackground}) => (
  <Wrapper>
    {withXBackground && <IconXWrapper>
      <IconX fill="fill-gray13" opacity={1} />
    </IconXWrapper>}
    {children}
  </Wrapper>
);

const IconXWrapper = styled.div.attrs({className: "absolute left-1/2 -translate-x-1/2 z-x"})`
    top: -12rem;
    height: 100rem;
    width: 78rem;
  
  @media ${theme.mediaQueries.tablet} {
    top: -24rem;
    height: 200rem;
    width: 166rem;
  }
`;

const Wrapper = styled.div.attrs({ className: "bg-white py-7 relative overflow-hidden tablet:py-16 tablet:rounded-lg" })``;

export default ContentWrapper;