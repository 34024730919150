import React from "react";
import styled from "styled-components";
import { BackButton, ButtonSolid } from "../../../components/button";
import OfferSummary from "./offer-summary";
import OfferContent from "./offer-content";
import OfferRecruiter from "./offer-recruiter";
import { theme } from "../../../styles/styles";
import OfferEndDate from "./offer-end-date";
import { JobOfferInterface } from "../../../graphql/jobs";
import { JobOfferDataInterface } from "../../../components/apply-job/apply-job-modal";
import { ApplyJobModal } from "../../../components/apply-job";
import OfferBackground from "./background";
import { Routes } from "../../../data/routing";

const Offer: React.FC<{ jobOffer: JobOfferInterface }> = ({ jobOffer }) => {
  const [currentOffer, setCurrentOffer] = React.useState<JobOfferDataInterface | undefined>();

  const onApplyClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentOffer({ offerId: jobOffer.jobOfferId, jobTitle: jobOffer.title });
  };

  return (
    <Wrapper>
      <OfferBackground category={jobOffer.category} />
      <Grid>
        <SummaryCell>
          <BackButtonWrapper>
            <BackButton
              to={Routes.jobs}
              shareTo={typeof window !== "undefined" ? window.location.href : ""}
            >
              Back to Jobs
            </BackButton>
          </BackButtonWrapper>
          <OfferSummary jobOffer={jobOffer} />
          <OfferFooter>
            <FooterButtonWrapper>
              <ButtonSolid href="#" onClick={onApplyClick}>
                Apply now
              </ButtonSolid>
            </FooterButtonWrapper>
            {jobOffer?.recruiter && (
              <OfferRecruiter recruiter={jobOffer?.recruiter} />
            )}
          </OfferFooter>
        </SummaryCell>
        <ContentCell>
          <OfferContent jobOffer={jobOffer} />
        </ContentCell>
        <MobileCell>
          {jobOffer.endDate && <OfferEndDate date={jobOffer.endDate} />}
          <ButtonSolid href="#" onClick={onApplyClick}>
            Apply now
          </ButtonSolid>
        </MobileCell>
      </Grid>
      <ApplyJobModal
        offerData={currentOffer}
        onClose={() => setCurrentOffer(undefined)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({
  className: "pt-20 tablet:pt-36 relative",
})``;

const Grid = styled.div.attrs({
  className:
    "grid gap-4 tablet:grid-cols-12 tablet:mb-16 tablet:mb-36 relative z-10 tablet:px-11",
})``;

const SummaryCell = styled.div.attrs({
  className:
    "grid mb-16 tablet:sticky tablet:top-24 tablet-col-start-1 tablet:col-span-5 tablet:justify-between tablet:mb-0",
})`
  min-height: 75vh;
  grid-template-rows: auto 1fr auto;

  @media ${theme.mediaQueries.tablet} {
    min-height: auto;
    height: 34.375rem;
  }
`;

const BackButtonWrapper = styled.div.attrs({
  className: "mb-12 tablet:mb-16 tablet:pl-5",
})``;

const ContentCell = styled.div.attrs({
  className:
    "tablet:col-start-6 tablet:col-span-7 tablet:pl-8 tablet:pr-16 tablet:mt-96 overflow-hidden",
})``;

const MobileCell = styled.div.attrs({
  className:
    "px-5 sticky flex justify-between bottom-0 inset-x-0 bg-gray py-4 border-t border-white/10 z-10 tablet:hidden",
})``;

const OfferFooter = styled.div.attrs({
  className: "grid gap-4 tablet:pl-5",
})`
  grid-template-columns: auto auto 1fr;
`;

const FooterButtonWrapper = styled.div.attrs({
  className: "hidden tablet:block flex-1",
})``;

export default Offer;
