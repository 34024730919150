import React from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { RecruitmentProcess } from "./recruitment-process";
import { RichTextField } from "../../../components/text-content-wrapper";
import { theme } from "../../../styles/styles";
import { JobOfferInterface } from "../../../graphql/jobs";
import BenefitsList from "./benefits-list";
import { graphql, useStaticQuery } from "gatsby";
import { PimcoreImage } from "../../../components/pimcore-image";

interface OfferContentProps {
  jobOffer: JobOfferInterface;
}

const OfferContent: React.FC<OfferContentProps> = ({ jobOffer }) => {
  const background = useStaticQuery(graphql`
    query {
      pimcore {
        desktop: getAsset(fullpath: "/pages/static/jobs/background-section1.jpg") {
          ...thumbnail
        }
        mobile: getAsset(fullpath: "/pages/static/jobs/background-section1_m.jpg") {
          ...thumbnail
        }
      }
    }
  `);

  return (
    <>
      {jobOffer.overview && (
        <SectionWrapper>
          <TitleSection>
            <Dot />
            <HeaderTitleSection>Job overview</HeaderTitleSection>
          </TitleSection>
          <ContentSectionWrapper>
            <ContentSection>
              <RichTextFieldLight dangerouslySetInnerHTML={{ __html: jobOffer.overview }} />
            </ContentSection>
          </ContentSectionWrapper>
        </SectionWrapper>
      )}
      {jobOffer.responsibilities && (
        <SectionWrapper>
          <TitleSection>
            <Dot />
            <HeaderTitleSection>Your day-to-day job</HeaderTitleSection>
          </TitleSection>
          <ContentSectionWrapper>
            <ContentSection>
              <RichTextFieldLight dangerouslySetInnerHTML={{ __html: jobOffer.responsibilities }} />
            </ContentSection>
          </ContentSectionWrapper>
        </SectionWrapper>
      )}
      {jobOffer.benefitsConfiguration && jobOffer.benefitsConfiguration !== "none" && (
        <SectionWrapper overflowingX>
          <TitleSection>
            <Dot />
            <HeaderTitleSection>Your benefits</HeaderTitleSection>
          </TitleSection>
          <ContentSectionWrapper>
            <ContentSectionMobileSlider>
              <BenefitsList type={"all"} />
            </ContentSectionMobileSlider>
          </ContentSectionWrapper>
        </SectionWrapper>
      )}
      {jobOffer.requirements && (
        <SectionWrapper>
          <TitleSection>
            <Dot />
            <HeaderTitleSection>We are looking for</HeaderTitleSection>
          </TitleSection>
          <ContentSectionWrapper>
            <ContentSection>
              <RichTextFieldLight dangerouslySetInnerHTML={{ __html: jobOffer.requirements }} />
            </ContentSection>
          </ContentSectionWrapper>
        </SectionWrapper>
      )}
      {jobOffer.recruitmentProcessType && (
        <SectionWrapper overflowingX>
          <TitleSection>
            <Dot />
            <HeaderTitleSection>Our recruitment process</HeaderTitleSection>
          </TitleSection>
          <ContentSectionWrapper>
            <ContentSectionMobileSlider>
              <RecruitmentProcess type={jobOffer.recruitmentProcessType} />
            </ContentSectionMobileSlider>
          </ContentSectionWrapper>
        </SectionWrapper>
      )}
      <SectionWrapper>
        <SectionInfoWrapper>
          <PimcoreImage image={background.pimcore.desktop} mobileImage={background.pimcore.mobile} withAspectRatio />
          <ContentInfoWrapper>
            <ContentInfoTitleWrapper>
              <ContentInfoTitle>
                Moving digital <br /> at Miele forward. <br /> <strong>Together.</strong>
              </ContentInfoTitle>
            </ContentInfoTitleWrapper>
            <ContentInfoParagraph>
              We are Miele X, Miele’s performance community of connected digital Xperts, pioneers and creative thinkers. A collaborative, forward-thinking team, all focused on building strong customer relationships and experiences.
              <br /> <br />
              Sitting at our heart is a diverse international community, powered by different expertise, cultures and experiences. Constantly inspiring and learning from each other, sharing a passion for ‘Immer Besser’ as we move forward as one.
              <br /> <br />
              All in a unique environment where physical and mental well-being is a priority, and everyone has the space to grow and develop.
            </ContentInfoParagraph>
            <ContentInfoTitle>
              <>
                We are looking forward to <strong>your</strong> application!
              </>
            </ContentInfoTitle>
          </ContentInfoWrapper>
        </SectionInfoWrapper>
      </SectionWrapper>
    </>
  );
};

const TitleSection = styled.div.attrs({
  className: "flex items-center mb-16",
})``;
const ContentSectionWrapper = styled.div.attrs({
  className: "ml-2.5 pl-2.5 border-l-2 border-white/10",
})``;
const ContentInfoWrapper = styled.div.attrs({
  className: "px-8 tablet:px-16 -mt-52 tablet:-mt-48",
})``;
const ContentInfoParagraph = styled.p.attrs({
  className: "text-gray8 text-xl font-light mb-16",
})``;
const ContentInfoTitleWrapper = styled.div.attrs({
  className: "mb-16 tablet:mb-24",
})``;
const ContentInfoTitle = styled.h3.attrs({
  className: `font-light text-subHeadlineMobile tablet:text-5xl text-white`,
})``;
const SectionInfoWrapper = styled.div.attrs({
  className: "tablet:ml-2.5 rounded-lg bg-gray2 pb-16 overflow-hidden",
})``;
const ContentSection = styled.div.attrs({
  className: "ml-5",
})``;
const ContentSectionMobileSlider = styled.div.attrs({
  className: "-ml-2.5 tablet:ml-5",
})``;

const SectionWrapper = styled.div.attrs<{ overflowingX?: boolean }>(({ overflowingX }) => ({
  className: `${overflowingX ? "pl-5" : "px-5"} tablet:px-0 mb-12 last:mb-0`,
}))<{ overflowingX?: boolean }>``;

const Dot = styled.div.attrs({
  className: "w-5 h-5 mr-2.5 rounded-full bg-yellow",
})``;
const HeaderTitleSection = styled.p.attrs({
  className: `text-white font-bold text-paragraphTablet ml-5 pr-4 tablet:pr-0 `,
})``;

const RichTextFieldLight: StyledComponent<"div", DefaultTheme> = styled(RichTextField).attrs({
  className: "text-white",
})`
  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    color: ${theme.colors.gray5};
    display: flex;

    &:before {
      content: "•";
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.5rem;
      color: ${theme.colors.white};
      display: block;
      margin-right: 0.75rem;
    }
  }
`;

export default OfferContent;
