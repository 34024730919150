import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Routes } from "../../data/routing";
import { PimcoreImage } from "../pimcore-image";

export interface SmallTileTypes {
  work: SmallTileData;
  team: SmallTileData;
  office: SmallTileData;
}

interface SmallTileData {
  label: string;
  path: string;
}

interface SmallTileProps {
  to: keyof SmallTileTypes;
}

const tileTypes: SmallTileTypes = {
  work: {
    label: "Our work",
    path: Routes.work,
  },
  team: {
    label: "Our team",
    path: Routes.team,
  },
  office: {
    label: "Our Community Space",
    path: Routes.office,
  },
};

const SmallTile: React.FC<SmallTileProps> = ({ to }) => {
  const images = useStaticQuery(graphql`
    query {
      pimcore {
        team: getAsset(fullpath: "/pages/static/tiles-navigation/our-team.jpg") {
          ... thumbnail
        }
        work: getAsset(fullpath: "/pages/static/tiles-navigation/our-work.jpg") {
          ... thumbnail
        }
        office: getAsset(fullpath: "/pages/static/tiles-navigation/our-office.jpg") {
          ... thumbnail
        }
      }
    }
  `);

  const backgroundImage = images?.pimcore?.[to];

  return (
    <Wrapper to={tileTypes[to].path}>
      <Background>
        {backgroundImage && <PimcoreImage image={backgroundImage} modifier="h-full w-full object-cover" withAspectRatio />}
      </Background>
      <Label>{tileTypes[to].label}</Label>
    </Wrapper>
  )
};

const Wrapper = styled(Link).attrs({
  className: "relative block h-full w-full",
})``;

const Background = styled.picture.attrs({
  className: "rounded-lg absolute inset-0 overflow-hidden",
})``;

const Label = styled.div.attrs({
  className: "absolute bottom-5 left-6 text-3xl font-light text-white",
})``;

export default SmallTile;
