import React from "react";

const ArrowComponent: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.485 14.142">
      <path
        id="Path_1066"
        data-name="Path 1066"
        d="M18,12v8"
        transform="translate(28.284 2.828) rotate(135)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_1067"
        data-name="Path 1067"
        d="M12,18h8"
        transform="translate(28.284 11.314) rotate(135)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowComponent;
