import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { PimcoreImage } from "../../../../components/pimcore-image";

const CustomerEngagementBackground: React.FC = () => {
  const images = useStaticQuery(graphql`query {
    pimcore {
      desktop: getAsset(fullpath: "/pages/static/jobs/customer-engagement-and-performance-marketing.jpg") {
        ...thumbnailFull
      }
      mobile: getAsset(fullpath: "/pages/static/jobs/customer-engagement-and-performance-marketing-mobile.jpg") {
        ...thumbnailFull
      }
    }
  }`);

  return (
    <PimcoreImage image={images.pimcore.desktop} mobileImage={images.pimcore.mobile} modifier="w-screen" loading="eager" withAspectRatio />
  )
};

export default CustomerEngagementBackground;