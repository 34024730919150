import styled from "styled-components";

const RichTextField = styled.div.attrs({ className: "text-paragraph tablet:text-xl font-light" })`
    p {
      margin-bottom: 1.25rem;
    }
  
    ul {
      list-style-type: disc;
      padding-left: 1.1rem;
    }
  
    li {
      margin-bottom: 1.25rem;
    }
    a {
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  `;

  export default RichTextField;