import React from "react";
import styled from "styled-components";
import { Routes } from "../../data/routing";
import { ButtonLinkSolid } from "../button";
import { Headline3, Paragraph } from "../typography";
import { theme } from "../../styles/styles";
import { graphql, useStaticQuery } from "gatsby";
import { PimcoreImage } from "../pimcore-image";

interface JobsSearchTileProps {
  jobsCount?: number;
}

const JobsSearchTile: React.FC<JobsSearchTileProps> = ({ jobsCount }) => {
  const images = useStaticQuery(graphql`
    query {
      pimcore {
        desktop: getAsset(fullpath: "/pages/static/tiles-navigation/jobs.jpg") {
          ... thumbnail
        }
        mobile: getAsset(fullpath: "/pages/static/tiles-navigation/jobs-mobile.jpg") {
          ... thumbnail
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Background>
        <PimcoreImage image={images.pimcore.desktop} mobileImage={images.pimcore.mobile} modifier="h-full w-full object-cover" withAspectRatio />
      </Background>
      <Content>
        <ContentWrapper>
          <Headline3>Search jobs</Headline3>
          <ContentLimiter>
            <Paragraph>Help to shape the digital future of one of the world’s best-loved brands.</Paragraph>
          </ContentLimiter>
        </ContentWrapper>
        <ContentWrapper>
          {jobsCount && <JobOffersWrapper>
            <JobOffersCount>{jobsCount}</JobOffersCount>
            <JobOffersCountLabel>open vacancies</JobOffersCountLabel>
          </JobOffersWrapper>}
          <ButtonLinkSolid to={Routes.jobs}>Discover jobs</ButtonLinkSolid>
        </ContentWrapper>
      </Content>
    </Wrapper>
  )
};

export const Wrapper = styled.div.attrs({ className: 'relative w-full h-full' })``;

export const Background = styled.picture.attrs({ className: 'rounded-lg absolute inset-0 overflow-hidden' })``;

export const Content = styled.div.attrs({ className: 'flex justify-between flex-col h-full relative p-5 tablet:p-14 tablet:pr-0 tablet:w-96' })``;

export const ContentWrapper = styled.div.attrs({ className: "" })``;

export const ContentLimiter = styled.div.attrs({ className: "mb-5 tablet:mb-10" })`
  max-width: 11rem;

  @media ${theme.mediaQueries.tablet} {
    max-width: 100%;
  }

  strong {
    font-weight: 300;

    @media ${theme.mediaQueries.tablet} {
      font-weight: bold;
    }
  }
`;

const JobOffersWrapper = styled.div.attrs({ className: 'mb-5 tablet:mb-10' })``;

const JobOffersCount = styled.div.attrs({ className: 'text-white font-bold text-7xl' })``;

const JobOffersCountLabel = styled.div.attrs({ className: 'text-white font-bold text-lg' })``;

export default JobsSearchTile;