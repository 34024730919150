import * as React from "react";
import { BenefitsTile, TileNavigation } from "../components/tile-navigation";
import Offer from "../sections/jobs/offer/offer";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout";
import { JobOfferInterface } from "../graphql/jobs";

interface JobOfferDataInterface {
  pimcore: {
    jobOffer: JobOfferInterface;
  }
}

const JobOffer: React.FC<PageProps<JobOfferDataInterface>> = ({ data }) => {
  const jobOffer = data.pimcore.jobOffer;

  return (
    <Layout
      navCurrentItem="jobs"
      title={jobOffer.metaTitle}
      description={jobOffer.metaDescription}
      canonical={process.env.GATSBY_BASE_URL + '/jobs/' + jobOffer.slug + '/'}
      openGraph={{
        title: jobOffer.metaTitle,
        description: jobOffer.metaDescription,
        type: 'article',
      }}
      schemaOrg={jobOffer.metaSchema}
    >
      <Main>
        <Offer jobOffer={data.pimcore.jobOffer} />
        <TileNavigation top={"work"} bottom={"team"}>
          <BenefitsTile />
        </TileNavigation>
      </Main>
    </Layout>
  );
};

const Main = styled.div.attrs({ className: "" })``;

export const query = graphql`
  query($id: Int!) {
    pimcore {
      jobOffer: getJobOffer(id: $id) {
        title
        location
        endDate
        category
        jobOfferId
        overview
        requirements
        responsibilities
        benefitsConfiguration
        recruitmentProcessType
        recruiter {
          ... on Pimcore_object_Recruiter {
            name
            email
            title
            photo {
              metadata {
                name
                data
              }
              dimensions {
                width
                height
              }
              srcset(thumbnail: "RecruiterPhoto") {
                descriptor
                resolutions(types: [1, 2]) {
                  url
                }
              }
            }
          }
        }
        slug
        metaTitle
        metaDescription
        metaSchema
      }
    }
  }
`;

export default JobOffer;
